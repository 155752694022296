var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "conter" }, [
    _c("div", { staticClass: "banFlex" }, [
      _c("div", { staticClass: "banleft" }, [
        _c(
          "span",
          { class: _vm.offSetWidth > 1360 ? "bg_title" : "bg_title_small" },
          [
            _vm._v(" 国内领先的多云环境 "),
            _c("br"),
            _vm._v(" PaaS统一治理平台 "),
          ]
        ),
        _c("div", { staticClass: "bg_hg" }),
        _c("span", { staticClass: "bg_titles" }, [_vm._v("技术驱动数字变革")]),
      ]),
      _c(
        "div",
        { staticClass: "banright" },
        [
          _c("a-carousel", { attrs: { autoplay: "" } }, [
            _c("div", [
              _c("img", {
                staticStyle: { width: "100%", height: "30rem" },
                attrs: { src: require("@/assets/homePage/bananer1.png") },
              }),
            ]),
            _c("div", [
              _c("img", {
                staticStyle: { width: "100%", height: "30rem" },
                attrs: { src: require("@/assets/homePage/bananer2.png") },
              }),
            ]),
          ]),
        ],
        1
      ),
    ]),
    _c("div", [
      _c("div", { staticClass: "boxTitle" }, [_vm._v("产品服务")]),
      _c("div", { staticClass: "cpfw_box" }, [
        _c("div", { staticClass: "cpfw_left", on: { click: _vm.goTo5 } }, [
          _c("img", {
            attrs: {
              src: require("@/assets/homePage/icon1.png"),
              width: "180px",
              height: "200px",
            },
          }),
          _vm._m(0),
        ]),
        _c("div", { staticClass: "cpfw_right", on: { click: _vm.goTo6 } }, [
          _c("img", {
            attrs: {
              src: require("@/assets/homePage/icon2.png"),
              width: "180px",
              height: "200px",
            },
          }),
          _vm._m(1),
        ]),
      ]),
      _c("div", { staticClass: "boxTitle" }, [_vm._v("我们的优势")]),
      _vm._m(2),
      _c("div", { staticClass: "boxTitle" }, [_vm._v("经典案例")]),
      _c("div", { staticClass: "fa_flex" }, [
        _c(
          "div",
          {
            staticClass: "fa_item",
            staticStyle: { "margin-right": "50px" },
            on: {
              click: function ($event) {
                return _vm.openPage(0)
              },
            },
          },
          [
            _vm._m(3),
            _c("div", { staticClass: "fa_nr" }, [
              _vm._v(
                " 某股份制银行拥有的Nginx节点和集群较多，部署复杂。无法满足银行快速上线的要求，导致银行业务系统呈现出开放性、用户量不可预测性、应用新功能更新频繁的场景；系统弹性扩展能力不足的情况。 "
              ),
            ]),
            _vm._m(4),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "fa_item",
            staticStyle: { "margin-right": "50px" },
            on: {
              click: function ($event) {
                return _vm.openPage(1)
              },
            },
          },
          [
            _vm._m(5),
            _c("div", { staticClass: "fa_nr" }, [
              _vm._v(
                " 为了同时满足业务部门对内部系统的统一管理和维护，落地建设了运维管理平台等相关系统。随着”一网四云”建设的逐步深入与IDC运维规模的扩张，管理效率低下，需要提高稳定性。 "
              ),
            ]),
            _vm._m(6),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "fa_item",
            staticStyle: { "margin-right": "50px" },
            on: {
              click: function ($event) {
                return _vm.openPage(2)
              },
            },
          },
          [
            _vm._m(7),
            _c("div", { staticClass: "fa_nr" }, [
              _vm._v(
                " 根据信创需求，需要支持信创版中间件，并且部署在其内部的麒麟系统上面，其内部服务与服务之间的中间件开始呈现复杂的依赖关系，系统运维的复杂度急剧增加。 "
              ),
            ]),
            _vm._m(8),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "fa_item",
            on: {
              click: function ($event) {
                return _vm.openPage(3)
              },
            },
          },
          [
            _vm._m(9),
            _c("div", { staticClass: "fa_nr" }, [
              _vm._v(
                " 需要将原有的、传统的服务，通过容器的方式，引入DevOps流程并使用蓝绿发布，让服务运行在云平台上。由于系统中各个应用服务部署方式、应用和运用之间的中间件的差异，在服务容器化时，面临着较多难题。 "
              ),
            ]),
            _vm._m(10),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav_contenr" }, [
      _c("div", { staticClass: "le_title" }, [
        _vm._v(" 多云PaaS治理平台 "),
        _c("br"),
        _vm._v(" CheerMaaS "),
      ]),
      _c("div", { staticClass: "bo_nr" }, [
        _vm._v(" 我们不制造中间件，但可以管理你使用的中间件 "),
        _c("br"),
        _vm._v(" 支持开源和商业版中间件类型 "),
        _c("br"),
        _vm._v(" 平台化管理，统一入口的中台化服务模式 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav_contenr" }, [
      _c("div", { staticClass: "le_title" }, [
        _vm._v(" 备份治理平台 "),
        _c("br"),
        _vm._v(" CheerVM "),
      ]),
      _c("div", { staticClass: "bo_nr" }, [
        _vm._v(" 成为支持智能数据管理的最可信任的备份解决方案提供商 "),
        _c("br"),
        _vm._v(" 提供面向未来的企业超可用性云智能数据管理平台 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ysbox" }, [
      _c("div", { staticClass: "ys_left" }, [
        _c("div", { staticClass: "ys_li" }, [
          _c("img", {
            staticStyle: { width: "60px", height: "60px" },
            attrs: { src: require("@/assets/homePage/icon4_1.png") },
          }),
          _c("div", { staticClass: "li_right" }, [
            _c("div", { staticClass: "li_one" }, [_vm._v("中间件管理")]),
            _c("div", { staticClass: "li_two" }, [
              _vm._v("核心功能,中间件统一纳管入口"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "ys_li" }, [
          _c("img", {
            staticStyle: { width: "60px", height: "60px" },
            attrs: { src: require("@/assets/homePage/icon4_2.png") },
          }),
          _c("div", { staticClass: "li_right" }, [
            _c("div", { staticClass: "li_one" }, [_vm._v("系统配置")]),
            _c("div", { staticClass: "li_two" }, [
              _vm._v("权限管理/用户管理/许可证"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "ys_li" }, [
          _c("img", {
            staticStyle: { width: "60px", height: "60px" },
            attrs: { src: require("@/assets/homePage/icon4_3.png") },
          }),
          _c("div", { staticClass: "li_right" }, [
            _c("div", { staticClass: "li_one" }, [_vm._v("安全审计")]),
            _c("div", { staticClass: "li_two" }, [
              _vm._v("操作日志/登录日志/运行日志"),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "ys_center" }, [
        _c("img", {
          staticStyle: { width: "100%", height: "auto" },
          attrs: { src: require("@/assets/homePage/icon3.png") },
        }),
      ]),
      _c("div", { staticClass: "ys_right" }, [
        _c("div", { staticClass: "ys_lis" }, [
          _c("div", { staticClass: "li_rights" }, [
            _c("div", { staticClass: "li_one" }, [_vm._v("资产管理")]),
            _c("div", { staticClass: "li_two" }, [
              _vm._v("集群信息／节点信息/配置信息"),
            ]),
          ]),
          _c("img", {
            staticStyle: { width: "60px", height: "60px" },
            attrs: { src: require("@/assets/homePage/icon5_1.png") },
          }),
        ]),
        _c("div", { staticClass: "ys_lis" }, [
          _c("div", { staticClass: "li_rights" }, [
            _c("div", { staticClass: "li_one" }, [_vm._v("数字化运维")]),
            _c("div", { staticClass: "li_two" }, [
              _vm._v("监控/告警/报表/可视化"),
            ]),
          ]),
          _c("img", {
            staticStyle: { width: "60px", height: "60px" },
            attrs: { src: require("@/assets/homePage/icon5_2.png") },
          }),
        ]),
        _c("div", { staticClass: "ys_lis" }, [
          _c("div", { staticClass: "li_rights" }, [
            _c("div", { staticClass: "li_one" }, [_vm._v("API管理")]),
            _c("div", { staticClass: "li_two" }, [
              _vm._v("丰富API接口，可供快速接入"),
            ]),
          ]),
          _c("img", {
            staticStyle: { width: "60px", height: "60px" },
            attrs: { src: require("@/assets/homePage/icon5_3.png") },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fa_title" }, [
      _c("span", [_vm._v("某股")]),
      _vm._v(" 份制银行 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fa_nr" }, [
      _c("a", [_vm._v("了解详情 ->")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fa_title" }, [
      _c("span", [_vm._v("某大")]),
      _vm._v(" 型证券A "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fa_nr" }, [
      _c("a", [_vm._v("了解详情 ->")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fa_title" }, [
      _c("span", [_vm._v("某大")]),
      _vm._v(" 型证券B "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fa_nr" }, [
      _c("a", [_vm._v("了解详情 ->")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fa_title" }, [
      _c("span", [_vm._v("某信")]),
      _vm._v(" 托登记机构 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fa_nr" }, [
      _c("a", [_vm._v("了解详情 ->")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }