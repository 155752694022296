<template>
    <div class="conter">
        <div class="banFlex">
            <div class="banleft">
                <span :class="offSetWidth > 1360 ? 'bg_title' : 'bg_title_small'">
                    国内领先的多云环境
                    <br />
                    PaaS统一治理平台
                </span>
                <div class="bg_hg"></div>
                <span class="bg_titles">技术驱动数字变革</span>
            </div>
            <div class="banright">
                <!-- 轮播 -->
                <a-carousel autoplay>
                    <div>
                        <img src="@/assets/homePage/bananer1.png" style="width: 100%; height: 30rem" />
                    </div>
                    <div>
                        <img src="@/assets/homePage/bananer2.png" style="width: 100%; height: 30rem" />
                    </div>
                </a-carousel>
            </div>
        </div>
        <div>
            <div class="boxTitle">产品服务</div>
            <div class="cpfw_box">
                <div class="cpfw_left" @click="goTo5">
                    <img src="@/assets/homePage/icon1.png" width="180px" height="200px" />
                    <div class="nav_contenr">
                        <div class="le_title">
                            多云PaaS治理平台
                            <br />
                            CheerMaaS
                        </div>
                        <div class="bo_nr">
                            我们不制造中间件，但可以管理你使用的中间件
                            <br />
                            支持开源和商业版中间件类型
                            <br />
                            平台化管理，统一入口的中台化服务模式
                        </div>
                    </div>
                </div>
                <div class="cpfw_right" @click="goTo6">
                    <img src="@/assets/homePage/icon2.png" width="180px" height="200px" />
                    <div class="nav_contenr">
                        <div class="le_title">
                            备份治理平台
                            <br />
                            CheerVM
                        </div>
                        <div class="bo_nr">
                            成为支持智能数据管理的最可信任的备份解决方案提供商
                            <br />
                            提供面向未来的企业超可用性云智能数据管理平台
                        </div>
                    </div>
                </div>
            </div>
            <div class="boxTitle">我们的优势</div>
            <div class="ysbox">
                <div class="ys_left">
                    <div class="ys_li">
                        <img src="@/assets/homePage/icon4_1.png" style="width: 60px; height: 60px" />
                        <div class="li_right">
                            <div class="li_one">中间件管理</div>
                            <div class="li_two">核心功能,中间件统一纳管入口</div>
                        </div>
                    </div>
                    <div class="ys_li">
                        <img src="@/assets/homePage/icon4_2.png" style="width: 60px; height: 60px" />
                        <div class="li_right">
                            <div class="li_one">系统配置</div>
                            <div class="li_two">权限管理/用户管理/许可证</div>
                        </div>
                    </div>
                    <div class="ys_li">
                        <img src="@/assets/homePage/icon4_3.png" style="width: 60px; height: 60px" />
                        <div class="li_right">
                            <div class="li_one">安全审计</div>
                            <div class="li_two">操作日志/登录日志/运行日志</div>
                        </div>
                    </div>
                </div>
                <div class="ys_center">
                    <img src="@/assets/homePage/icon3.png" style="width: 100%; height: auto" />
                </div>
                <div class="ys_right">
                    <div class="ys_lis">
                        <div class="li_rights">
                            <div class="li_one">资产管理</div>
                            <div class="li_two">集群信息／节点信息/配置信息</div>
                        </div>
                        <img src="@/assets/homePage/icon5_1.png" style="width: 60px; height: 60px" />
                    </div>
                    <div class="ys_lis">
                        <div class="li_rights">
                            <div class="li_one">数字化运维</div>
                            <div class="li_two">监控/告警/报表/可视化</div>
                        </div>
                        <img src="@/assets/homePage/icon5_2.png" style="width: 60px; height: 60px" />
                    </div>
                    <div class="ys_lis">
                        <div class="li_rights">
                            <div class="li_one">API管理</div>
                            <div class="li_two">丰富API接口，可供快速接入</div>
                        </div>
                        <img src="@/assets/homePage/icon5_3.png" style="width: 60px; height: 60px" />
                    </div>
                </div>
            </div>
            <div class="boxTitle">经典案例</div>
            <div class="fa_flex">
                <div class="fa_item" @click="openPage(0)" style="margin-right: 50px">
                    <div class="fa_title">
                        <span>某股</span>
                        份制银行
                    </div>
                    <div class="fa_nr">
                        某股份制银行拥有的Nginx节点和集群较多，部署复杂。无法满足银行快速上线的要求，导致银行业务系统呈现出开放性、用户量不可预测性、应用新功能更新频繁的场景；系统弹性扩展能力不足的情况。
                    </div>
                    <div class="fa_nr">
                        <a>了解详情 -></a>
                    </div>
                </div>
                <div class="fa_item" @click="openPage(1)" style="margin-right: 50px">
                    <div class="fa_title">
                        <span>某大</span>
                        型证券A
                    </div>
                    <div class="fa_nr">
                        为了同时满足业务部门对内部系统的统一管理和维护，落地建设了运维管理平台等相关系统。随着”一网四云”建设的逐步深入与IDC运维规模的扩张，管理效率低下，需要提高稳定性。
                    </div>
                    <div class="fa_nr">
                        <a>了解详情 -></a>
                    </div>
                </div>
                <div class="fa_item" @click="openPage(2)" style="margin-right: 50px">
                    <div class="fa_title">
                        <span>某大</span>
                        型证券B
                    </div>
                    <div class="fa_nr">
                        根据信创需求，需要支持信创版中间件，并且部署在其内部的麒麟系统上面，其内部服务与服务之间的中间件开始呈现复杂的依赖关系，系统运维的复杂度急剧增加。
                    </div>
                    <div class="fa_nr">
                        <a>了解详情 -></a>
                    </div>
                </div>
                <div class="fa_item" @click="openPage(3)">
                    <div class="fa_title">
                        <span>某信</span>
                        托登记机构
                    </div>
                    <div class="fa_nr">
                        需要将原有的、传统的服务，通过容器的方式，引入DevOps流程并使用蓝绿发布，让服务运行在云平台上。由于系统中各个应用服务部署方式、应用和运用之间的中间件的差异，在服务容器化时，面临着较多难题。
                    </div>
                    <div class="fa_nr">
                        <a>了解详情 -></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomePage',
    data() {
        return {
            offSetWidth: 0 // 当前窗口宽度
        }
    },
    mounted() {
        this.offSetWidth = document.body.offsetWidth
        window.onresize = () => {
            return (() => {
                this.offSetWidth = document.body.offsetWidth
            })()
        }
    },
    methods: {
        //解决方案跳转
        openPage: function (code) {
            this.$router.push({
                path: '/casePage',
                query: {
                    index: code
                }
            })
        },
        //跳转到CheerOcean 中间件治理系统
        goTo5() {
            this.$router.push('/product/cheer-maas')
        },
        //CheerVM 备份管理平台
        goTo6() {
            this.$router.push('/product/cheer-vm')
        }
    },
    watch: {
        // 当浏览器宽度小于900px时 隐藏图片，表单居中
        offSetWidth(val) {
            this.offSetWidth = val
        }
    }
}
</script>

<style scoped>
/**解决方案 */
.fa_nr {
    margin-top: 30px;
    font-size: 18px;
    color: #333;
    line-height: 180%;
    text-align: justify;
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
}
.fa_title span {
    border-bottom: 4px solid #409eff;
    padding-bottom: 10px;
}
.fa_title {
    font-weight: bold;
    font-size: 22px;
}
.fa_item {
    flex: 1;
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    padding: 30px 30px 40px 30px;
}
.fa_item:hover {
    box-shadow: 0px 3px 32px 0px rgba(64, 158, 255, 0.5);
    transform: translateY(-12px);
    transition: 0.8s;
    cursor: pointer;
}
.fa_flex {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    align-content: space-between;
    width: 80%;
    margin: 0 auto;
}
.li_two {
    font-size: 16px;
}
.li_one {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 6px;
}
.li_right {
    flex: 1;
    margin-left: 10px;
}
.li_rights {
    flex: 1;
    margin-right: 10px;
    text-align: right;
}
/** 我们的优势*/
.ys_li {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 60px 0;
}
.ys_lis {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 0 60px 0;
}
.ys_left {
    flex: 1;
}
.ys_right {
    flex: 1;
}
.ys_center {
    width: 50%;
    height: auto;
}
.bo_nr {
    font-size: 16px;
    text-align: justify;
    line-height: 180%;
}
.ysbox {
    display: -webkit-flex; /* Safari */
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}
/**头部 */
.conter {
    padding: 20px 0;
    background-image: url('../../assets/homePage/home_bg.png');
    background-position: 100% 0;
    background-size: 40% 38rem;
    background-repeat: no-repeat;
}
.banFlex {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 0 50px; */
    width: 95%;
    margin: 0 auto;
}
.banleft {
    flex: 1;
    margin-left: 50px;
}
.banright {
    width: 70%;
}
.bg_title {
    font-size: 38px;
    font-weight: 500;
    color: #333;
}
.bg_title_small {
    font-size: 34px;
    font-weight: 500;
    color: #333;
}
.bg_titles {
    font-size: 24px;
    font-weight: 400;
    color: #333;
}
.bg_hg {
    border-top: 10px solid #409eff;
    width: 60px;
    margin-top: 10px;
    margin-bottom: 30px;
}
/* 轮播图 */
.ant-carousel >>> .slick-slide {
    height: auto;
    overflow: hidden;
}

.ant-carousel >>> .slick-slide h3 {
    color: #fff;
}
.boxTitle {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    padding: 30px;
    color: #191c3d;
}
/**产品服务 */
.cpfw_box {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 80%;
    margin: 0 auto;
}
.cpfw_left {
    flex: 1;
    background: #ffffff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    padding: 30px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.cpfw_left:hover {
    box-shadow: 0px 3px 32px 0px rgba(64, 158, 255, 0.5);
    transform: translateY(-12px);
    transition: 0.8s;
    cursor: pointer;
}
.cpfw_right:hover {
    box-shadow: 0px 3px 32px 0px rgba(64, 158, 255, 0.5);
    transform: translateY(-12px);
    transition: 0.8s;
    cursor: pointer;
}
.cpfw_right {
    flex: 1;
    background: #ffffff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    margin-left: 30px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.le_title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}
.nav_contenr {
    flex: 1;
    margin-left: 16px;
}
</style>
